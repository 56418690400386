div.ReactTable div.rt-tbody div.rt-tr-group .rt-tr:hover {
  background: rgba(255, 255, 255, 0.25) !important;
}
.rt-thead {
  background: #f9fafb;
}
.rt-thead.-header {
  font-weight: 900;
  font-size: 14px;
}
div.ReactTable .rt-tbody .rt-tr.-odd {
  background: white;
}
div.ReactTable .rt-tbody .rt-tr.-even {
  background: #f9fafb;
}
div.ReactTable .rt-tbody .rt-td.centered {
  text-align: center;
}
div.ReactTable .rt-tbody .rt-td.right {
  text-align: right;
}
div.ReactTable .rt-tbody .rt-td.no-wrap {
  white-space: inherit;
}
